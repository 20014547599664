import React, { type FC, type ReactElement } from 'react';
import classnames from 'classnames';
import { Image } from 'bb/common/images';
import { type ImageData } from 'bb/common/images/types';
import { Text, useTranslation } from 'bb/i18n';
import { type Color } from 'bb/style/types';
import { Box, Stack } from 'bb/ui';
import css from './howItWorks.module.scss';
import { HOW_IT_WORKS_IMG_CONFIG } from './imageConfig';

export interface HowItWorksCardProps {
    step: number;
    img: ImageData;
    bgColor: Color;
    header: string;
    text: ReactElement;
    headerValues?: Record<string, unknown>;
}

export const HowItWorksCard: FC<HowItWorksCardProps> = ({
    step,
    img,
    bgColor,
    header,
    text,
    headerValues
}) => {
    useTranslation(['start', 'common', 'giftcard']);

    return (
        <Stack
            className={classnames(css.maxWidthContainer, css.card)}
            style={{ height: 'auto' }}
            data-testid="howItWorksCard"
        >
            <Image img={img} config={HOW_IT_WORKS_IMG_CONFIG} />
            <Box bgColor={bgColor} className={css.box} padding="small">
                <Stack alignItems="flexStart">
                    <Text
                        as="h3"
                        marginBottom="extraSmall"
                        data-optimizely={`howItWorks-${step}`}
                    >
                        {step}.{' '}
                        <Text as="span" values={headerValues} t={header} />
                    </Text>

                    {text}
                </Stack>
            </Box>
        </Stack>
    );
};

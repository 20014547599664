import React from 'react';
import { type Campaign, type PriceRule } from 'bb/campaigns/types';
import { type ImageData } from 'bb/common/images/types';

export type StudentImageDataPosition = 'studentpageTop' | 'studentUSP';

export type StudentImageDataObject = Record<
    StudentImageDataPosition,
    ImageData | null
>;

export type TrialDays = number | null;

export type StudentProviderProps = {
    campaign: Campaign;
    trialDays: TrialDays;
    children: React.ReactNode | ((ctx: IStudentContext) => React.ReactNode);
    imageData: StudentImageDataObject;
};

export type IStudentContext = {
    // Passed down to provider to provide easy access
    campaign: Campaign;
    trialDays: TrialDays;
    imageData: StudentImageDataObject;
    // Computed properties
    fullPrice: number;
    studentPrice: number;
    studentHours: number;
    studentPriceRule: PriceRule;
};

export const StudentContext = React.createContext({} as IStudentContext);

export const useStudent = () => React.useContext(StudentContext);

export const StudentProvider = ({
    children,
    campaign,
    trialDays,
    imageData
}: StudentProviderProps) => {
    /**
     * Typecast below to so they are never undefined
     * since they never will be.
     */
    const studentPriceRule = campaign.priceRules?.find(
        (rule) => rule.productDiscount
    ) as PriceRule;
    const fullPrice = campaign.products?.find((product) =>
        studentPriceRule?.limitedForProducts.includes(product.type)
    )?.originalPrice as number;
    const studentPrice = studentPriceRule?.productDiscount as number;
    const studentHours = campaign?.products?.find(
        (product) => product.type === 'basic'
    )?.hourLimit as number;

    const ctx: IStudentContext = React.useMemo(
        () => ({
            campaign,
            fullPrice,
            imageData,
            studentHours,
            studentPrice,
            studentPriceRule,
            trialDays
        }),
        [
            campaign,
            fullPrice,
            imageData,
            studentHours,
            studentPrice,
            studentPriceRule,
            trialDays
        ]
    );

    return (
        <StudentContext.Provider value={ctx}>
            {typeof children === 'function' ? children(ctx) : children}
        </StudentContext.Provider>
    );
};

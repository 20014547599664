import { type ImageConfig } from 'bb/api/server/contentful/types';
import { type ImageSrcFormatter } from './types';

// Entry from contentful is the wrong type // TODO fix later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const imageConfigMapper = (imageConfigs: any[] = []): ImageConfig => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images: imageConfigs[0]?.items.map((item: any) => ({
        title: item?.title,
        position: item?.position,
        altText: item?.altText,
        src: item?.image?.fields?.file?.url
    }))
});

export const formatAkamaiSrc: ImageSrcFormatter = (src, width) =>
    `${src}?w=${width}`;

export const formatSrc: ImageSrcFormatter = (src, width, format = '') => {
    const formatStr = format ? `&fm=${format}` : '';
    return `${src}?w=${width}&q=75${formatStr}`;
};

export const HOW_IT_WORKS_IMG_CONFIG = {
    sizes: [
        {
            screenMaxWidth: 768,
            width: 400
        },
        {
            screenMaxWidth: 992,
            width: 280
        },
        {
            screenMaxWidth: 4096,
            width: 400
        }
    ]
};

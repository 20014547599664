// @ts-strict-ignore
import React from 'react';
import NextHead from 'next/head';
import { useApp } from 'bb/app/AppContext';
import { type Route, type RouterQuery } from 'bb/app/router';
import { getUrl } from 'bb/app/router/utils';
import {
    ALL_MARKETS,
    getLocaleByMarket,
    isCoreMarket
} from 'bb/config/marketConfigUtils';
import { getCanonicalPageUrl } from 'bb/discovery/utils/utils';
import { useTranslation } from 'bb/i18n';
import { useGetNumberOfBooks } from '../NumberOfBooks';

type MetaDescriptionProps = {
    description?: string;
    title?: string;
    canonicalUrl?: string;
    children?: React.ReactNode;
    noIndex?: boolean;
    withAlternateLinks?: boolean;
    withIndexFollow?: boolean;
    route?: Route;
    query?: RouterQuery;
};

export const Head: React.FC<MetaDescriptionProps> = ({
    description,
    title,
    canonicalUrl,
    children,
    noIndex,
    withAlternateLinks,
    withIndexFollow,
    route,
    query
}) => {
    const { t } = useTranslation();
    const numberOfBooks = useGetNumberOfBooks()();
    const { market: currentMarket } = useApp();

    const canonicalHref =
        canonicalUrl ||
        (route
            ? getCanonicalPageUrl(
                  getUrl(route?.href || '', { ...query, market: currentMarket })
              )
            : null);

    return (
        <NextHead>
            <title>{title || t('common:metaTitle')}</title>
            <meta
                property="og:title"
                content={title || t('common:metaTitle')}
            />
            {noIndex && <meta name="robots" content="noindex" />}
            {withIndexFollow && !noIndex && (
                <meta name="robots" content="index, follow" />
            )}
            {canonicalHref && (
                <>
                    <meta property="og:url" content={canonicalHref} />
                    <link rel="canonical" href={canonicalHref} />
                </>
            )}
            {withAlternateLinks &&
                route &&
                ALL_MARKETS.filter((market) => isCoreMarket(market)).map(
                    (market) => {
                        if (market === currentMarket) {
                            return null;
                        }

                        const alternateHref = getCanonicalPageUrl(
                            getUrl(route.href, {
                                ...query,
                                market
                            })
                        );
                        const locale = getLocaleByMarket(market);

                        return (
                            <link
                                key={market}
                                rel="alternate"
                                hrefLang={locale}
                                href={alternateHref}
                            />
                        );
                    }
                )}
            <meta
                name="description"
                content={
                    description ||
                    t('common:metaDescription', {
                        numberOfBooks
                    })
                }
            />
            <meta
                name="og:description"
                content={
                    description ||
                    t('common:metaDescription', {
                        numberOfBooks
                    })
                }
            />
            <meta name="keywords" content={t('common:metaKeywords')} />

            {children}
        </NextHead>
    );
};

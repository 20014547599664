// @ts-strict-ignore
import React, { type FunctionComponent } from 'react';
import { Link } from 'bb/app/nav';
import { useRouter } from 'bb/app/router';
import { Section } from 'bb/common';
import { MaxWidthContainer } from 'bb/common/Containers';
import { Header, Text, useTranslation } from 'bb/i18n';
import { Flex, DeprecatedButton, Stack } from 'bb/ui';
import { ILLUSTRATIONS } from './config';
import css from './howItWorks.module.scss';
import { HowItWorksCard } from './HowItWorksCard';

export const GiftcardHowItWorks: FunctionComponent = () => {
    const { t } = useTranslation(['start', 'common']);
    const { routes } = useRouter();

    const steps = [
        {
            id: 1,
            img: ILLUSTRATIONS.looking,
            backgroundColor: 'primary-purple',
            header: 'giftcard:digitalGiftcard',
            text: (
                <Text t="giftcard:digitalGiftcardText">
                    <Link route={routes.registration} />
                </Text>
            )
        },
        {
            id: 4,
            img: ILLUSTRATIONS.listen,
            backgroundColor: 'secondary-green',
            header: 'giftcard:giftCardHeader',
            text: <Text t="giftcard:giftCardText" />
        },
        {
            id: 2,
            img: ILLUSTRATIONS.downloadApp,
            backgroundColor: 'secondary-yellow',
            header: 'start:downloadAppHeader',
            text: (
                <Text t="start:downloadAppText">
                    <DeprecatedButton
                        as="a"
                        inline
                        variant="underline"
                        href={t('common:appStoreLink')}
                    />
                    <DeprecatedButton
                        as="a"
                        inline
                        variant="underline"
                        href={t('common:playStoreLink')}
                    />
                </Text>
            )
        },
        {
            id: 3,
            img: ILLUSTRATIONS.ears,
            backgroundColor: 'secondary-red',
            header: 'start:offertHeader',
            text: <Text t="start:offertText" />
        }
    ] as const;

    return (
        <Section withPagePadding padding="large" className={css.dark}>
            <Stack>
                <Header
                    as="h2"
                    marginTop="none"
                    center
                    t="giftcard:giftcardHowItWorksHeader"
                />
                <MaxWidthContainer maxWidth={400}>
                    <Text
                        t="giftcard:giftcardHowItWorksText"
                        center
                        marginTop="small"
                        marginBottom="large"
                    />
                </MaxWidthContainer>

                <Flex className={css.flex} alignItems="center">
                    {steps.map(
                        ({ img, backgroundColor, header, text, id }, index) => (
                            <HowItWorksCard
                                key={id}
                                step={index + 1}
                                img={img}
                                bgColor={backgroundColor}
                                header={header}
                                text={text}
                            />
                        )
                    )}
                </Flex>
            </Stack>
        </Section>
    );
};

// @ts-strict-ignore
import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import { type ContentImage } from 'bb/api/server/contentful/page/fetchPage';
import css from './image.module.scss';
import { formatAkamaiSrc, formatSrc } from './utils';
import type {
    ImageData,
    ImageConfig,
    ImageSizeConfig,
    ImageSrcFormatter
} from './types';

type ImageProps = {
    className?: string;
    img?: ImageData | ContentImage | null;
    config: ImageConfig;
    cover?: boolean;
} & React.ComponentProps<'img'>;

type ImageSourceProps = {
    format: string;
    formatter: ImageSrcFormatter;
    imgSizeConfig: ImageSizeConfig;
    strippedSrc: string;
    type?: string;
    ignoreRetinaMultiplier?: boolean;
};

const ImageSource: FunctionComponent<ImageSourceProps> = ({
    format,
    imgSizeConfig,
    strippedSrc,
    type,
    formatter,
    ignoreRetinaMultiplier = false
}) => (
    <source
        key={`s-${imgSizeConfig.screenMaxWidth}`}
        media={`(max-width: ${imgSizeConfig.screenMaxWidth}px)`}
        type={type}
        srcSet={`${formatter(
            strippedSrc,
            imgSizeConfig.width,
            format
        )}, ${formatter(
            strippedSrc,
            ignoreRetinaMultiplier
                ? imgSizeConfig.width
                : imgSizeConfig.width * 1.5,
            format
        )} 2x`}
    />
);

/**
 *
 * The config.sizes array should be in order from smallest breakpoint (screenMaxWidth) to largest.
 * The last value in the array should be the value used for all larger screens (think 4k - 8k).
 *
 * Example with image to span the entire viewport:
 * [{width: 370, screenMaxWidth: 370}, {width: 1920, screenMaxWidth: 1920}, {width: 2048, screenMaxWidth: 2048}]
 *
 * The image config for screens larger than 2048px will use the same config as the last value ☝️
 *
 */
export const Image: FunctionComponent<ImageProps> = ({
    className,
    img,
    cover,
    config,
    loading = 'lazy',
    ...pictureProps
}) => {
    if (!img?.src) {
        return null;
    }
    /**
     * Remove any existing query params
     */
    const strippedSrc = img.src.split('?')[0];
    const akamaiSrc = img.src.includes('akamaized');
    const formatter = akamaiSrc ? formatAkamaiSrc : formatSrc;

    return (
        <picture
            {...pictureProps}
            className={classnames(css.base, className, {
                [css.cover]: cover
            })}
        >
            {config.sizes.map((imgSizeConfig) => [
                !akamaiSrc && (
                    <ImageSource
                        key="webp"
                        format={config.format || 'webp'}
                        formatter={formatter}
                        imgSizeConfig={imgSizeConfig}
                        strippedSrc={strippedSrc}
                        type="image/webp"
                        ignoreRetinaMultiplier={config.ignoreRetinaMultiplier}
                    />
                ),
                <ImageSource
                    format={config.format}
                    formatter={formatter}
                    imgSizeConfig={imgSizeConfig}
                    strippedSrc={strippedSrc}
                    ignoreRetinaMultiplier={config.ignoreRetinaMultiplier}
                    key=""
                />
            ])}
            <img
                className={classnames(css.base, className, {
                    [css.cover]: cover
                })}
                src={formatter(
                    strippedSrc,
                    config?.sizes?.slice(-1)[0]?.width,
                    config.format
                )}
                title={img?.title}
                alt={img?.altText}
                loading={loading}
            />
        </picture>
    );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'bb/app/nav/Link';
import { useRouter } from 'bb/app/router';
import { Block } from 'bb/common/components';
import { PaddedBox, type PaddedBoxProps } from 'bb/common/components/PaddedBox';
import {
    Header,
    LimitaryText,
    useTranslation,
    type TranslationProps
} from 'bb/i18n';
import { BooksIcon, Row, Stack } from 'bb/ui';

/**
 * In most cases a translation key is passed.
 * However, if needed an entire translation object
 * can be passed. If the data is dynamic from API
 * or CMS children can be passed.
 */
export type HeadingProp =
    | TranslationProps
    | TranslationProps['t']
    | { children: React.ReactNode };

export type BookListCarouselSectionProps = {
    children: React.ReactNode;
    bgColor?: PaddedBoxProps['bgColor'];
    heading: HeadingProp;
    subHeading?: HeadingProp;
    hideShowAll?: boolean;
};

export const makeTranslationProps = (headingProp: HeadingProp) =>
    typeof headingProp === 'object' ? headingProp : { t: headingProp };

const BookListCarouselSection = ({
    children,
    bgColor = 'primary-black',
    heading,
    subHeading,
    hideShowAll = false,
    ...restProps
}: BookListCarouselSectionProps) => {
    const { t } = useTranslation();
    const { routes } = useRouter();

    return (
        <PaddedBox as="section" bgColor={bgColor} {...restProps}>
            <Block withPagePadding paddingBottom="large">
                <Stack>
                    <Header
                        as="h2"
                        color="primary-white"
                        center
                        size="medium"
                        data-optimizely="booklist-carousel-header"
                        {...makeTranslationProps(heading)}
                    />

                    {subHeading && (
                        <LimitaryText
                            marginTop="medium"
                            color="primary-white"
                            center
                            data-optimizely="booklist-carousel-subHeader"
                            {...makeTranslationProps(subHeading)}
                        />
                    )}

                    {!hideShowAll && (
                        <Row
                            marginTop="medium"
                            alignItems="center"
                            justifyContent="center"
                            data-optimizely="booklist-carousel-booksLink"
                        >
                            <BooksIcon color="primary-purple" size="small" />
                            <Link
                                data-optimizely="booklist-carousel-booksLinkText"
                                route={routes.books}
                                color="primary-white"
                            >
                                {t('start:booksLink')}
                            </Link>
                        </Row>
                    )}
                </Stack>
            </Block>
            {children}
        </PaddedBox>
    );
};

export default BookListCarouselSection;

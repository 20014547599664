export const FULL_IMAGE_CONFIG = {
    sizes: [
        {
            screenMaxWidth: 370,
            width: 370
        },
        {
            screenMaxWidth: 450,
            width: 450
        },
        {
            screenMaxWidth: 768,
            width: 768
        },
        {
            screenMaxWidth: 1200,
            width: 1200
        },
        {
            screenMaxWidth: 1920,
            width: 1920
        },
        {
            screenMaxWidth: 2560,
            width: 2560
        },
        {
            screenMaxWidth: 4096,
            width: 4096
        }
    ]
};

/**
 * For images what are full screen width on mobile (100vw) and half screen (50vw) width on desktop
 */
export const SM_100VW_LG_50VW = {
    sizes: [
        {
            screenMaxWidth: 370,
            width: 370
        },
        {
            screenMaxWidth: 450,
            width: 450
        },
        {
            screenMaxWidth: 767, // breakpoint to 2 columns is at 768px
            width: 768
        },
        {
            screenMaxWidth: 1920,
            width: 1920 / 2
        },
        {
            screenMaxWidth: 2560,
            width: 2560 / 2
        },
        {
            screenMaxWidth: 4096,
            width: 4096 / 2
        }
    ]
};
